import { gql } from 'apollo-boost'

export default gql`
  mutation Subscribe(
    $product: String!
    $variant: String!
    $returnUrl: String!
    $coupon: String
  ) {
    subscribeToPlan(
      product: $product
      variant: $variant
      payment: "vipps"
      coupon: $coupon
      returnUrl: $returnUrl
    ) {
      redirectUrl
    }
  }
`
