import { gql } from 'apollo-boost'

export default gql`
  query Me {
    products {
      id
      title
      description
      includes
      variants {
        id
        price
        months
        promotion {
          id
          title
          price
          description
          eligible
        }
      }
    }
    me {
      node {
        id
        plans {
          id
          start
          end
          product {
            id
            title
          }
        }
        consents {
          id
          type
        }
      }
    }
  }
`
