import React, { useState, useEffect, useCallback } from 'react'
import T from 'prop-types'
import { Link, graphql, navigate } from 'gatsby'
import { get } from 'lodash'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'

import { Loader, Form } from '@components'
import {
  DOWNLOAD_FROM_APPLE,
  DOWNLOAD_FROM_GOOGLE,
} from '../data/download-links'
import SEO from '../components/SEO'
import SignInWithFacebook from '../components/SignInWithFacebook'
import Price from '../components/Price'
import { Article, Input } from '../_shared/components'
import SignInWithApple from '../components/SignInWithApple'
import { client } from '../apollo/client'
import VIPPS_SUBSCRIBE_MUTATION from '../queries/vipps-subscribe'
import REFRESH_PENDING_MUTATION from '../queries/refresh-pending-plans'
import GIVE_CONSENT_MUTATION from '../queries/give-consent'
import VERIFY_COUPON_QUERY from '../queries/verify-coupon'
import ME_QUERY from '../queries/me'
import { setColors } from '../lib/colors'
import useSignup from '../hooks/signup'
import useLogin from '../hooks/login'
import useSocialSignup from '../hooks/sogicalSignup'
import Button from '../components/Button'
import SharedButton from '../_shared/components/Button'
import { Redirect } from '@reach/router';

const RedirectPage = () => (
  <Redirect from="/gavekort/los-inn/" to="" noThrow />
);
export default RedirectPage;
const PRODUCT_ID = 'full'

// export default function Page({
//   location,
//   data: {
//     products: { initialProducts },
//   },
// }) {
//   const subscription = get(location, 'state.subscription')
//   const [coupon, setCoupon] = useState(null)
//   const [waitRetries, setWaitRetries] = useState(0)
//   const [consentAnswered, setConsentAnswered] = useState(false)
//   const [step, setStep] = useState(
//     (typeof window !== 'undefined'
//       ? window.location.hash.match(/^#?(.*)/)[1]
//       : null) || 'user',
//   )

//   const [action, setAction] = useState('signup')
//   const [authType, setAuthType] = useState(null)
//   const errorState = useState(null)
//   const [error, setError] = errorState

//   const [formData, setFormData] = useState({
//     subscription: subscription || 'intro',
//   })
//   const [pendingPayment, setPendingPayment] = useState(false)

//   const { token: signupToken, signup } = useSignup(
//     errorState,
//     setAuthType,
//     formData.email,
//     formData.password,
//   )

//   const { token: loginToken, login } = useLogin(
//     errorState,
//     setAuthType,
//     formData.email,
//     formData.password,
//   )

//   const { token: socialSignupToken, signup: socialSignup } = useSocialSignup(
//     errorState,
//     setAuthType,
//   )

//   const [subscribe] = useMutation(VIPPS_SUBSCRIBE_MUTATION)
//   const [giveConsent] = useMutation(GIVE_CONSENT_MUTATION)
//   const [refreshPendingPlans] = useMutation(REFRESH_PENDING_MUTATION)

//   const [
//     verifyCoupon,
//     {
//       called: verifyCouponCalled,
//       loading: loadingCoupon,
//       data: {
//         verifyCoupon: { valid: validCoupon = false, coupon: couponData } = {},
//       } = {},
//     },
//   ] = useLazyQuery(VERIFY_COUPON_QUERY, { fetchPolicy: 'network-only' })

//   const {
//     loading,
//     data: { me, products: userProducts } = {},
//     refetch,
//   } = useQuery(ME_QUERY)

//   // Show products fetched when doing SSR until the client side fetched ones are ready
//   const products = userProducts || initialProducts || []
//   const user = get(me, 'node')

//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   const moveToStep = useCallback(step => {
//     window.location.hash = step
//     setStep(step)
//   })

//   useEffect(() => {
//     coupon &&
//       verifyCoupon({
//         variables: {
//           code: `${coupon}`.toLocaleUpperCase(),
//         },
//       })
//   }, [coupon])

//   useEffect(() => {
//     const interval = setInterval(async () => {
//       // if waitRetries > 0 but now on waiting page, reset it
//       if (step !== 'waiting' && waitRetries) {
//         setWaitRetries(0)
//       }

//       // do nothing if not on waiting page
//       if (step !== 'waiting') {
//         return
//       }

//       // give up if retried more than 15 times
//       if (step === 'waiting' && waitRetries > 15) {
//         moveToStep('timeout')
//         return
//       }

//       // refresh plans and the «me query»
//       await refreshPendingPlans({ refetchQueries: [{ query: ME_QUERY }] })
//       setWaitRetries(waitRetries => waitRetries + 1)

//       // if we now have a plan, send the user to the completed step
//       if (get(me, 'node.plans').length) {
//         moveToStep('completed')
//       }
//     }, 2000)

//     return () => clearInterval(interval)
//   }, [me, moveToStep, refreshPendingPlans, step, waitRetries])

//   useEffect(() => {
//     if (step === 'payment') {
//       setColors('inverted')
//       document.body.classList.add('dark')
//     } else if (
//       ['user', 'waiting', 'timeout', 'completed', 'existing'].indexOf(step) > -1
//     ) {
//       setColors('blue')
//       document.body.classList.remove('dark')
//     } else {
//       document.body.classList.remove('dark')
//     }
//   }, [step])

//   useEffect(() => {
//     // Do not send users somewhere before we know about the user
//     if (loading) {
//       return
//     }

//     // Non-signed in should start at the user step
//     if (!user && step === 'user') {
//       return
//     }
//     if (!user) {
//       return moveToStep('user')
//     }

//     // Signed in users without plans should go to the payment step
//     if (step === 'user' && user && user.plans.length === 0) {
//       return moveToStep('payment')
//     }

//     // Users with plans that go to user or payment step should be redirected to existing "step"
//     if (['user', 'payment'].indexOf(step) > -1 && user && user.plans.length) {
//       return moveToStep('existing')
//     }
//   }, [loading, moveToStep, step, user])

//   const updateForm = key => value => setFormData({ ...formData, [key]: value })

//   // eslint-disable-next-line space-before-function-paren
//   const handlePayment = async () => {
//     const product = products.find(({ id }) => id === PRODUCT_ID)
//     const variant = product.variants[0] || {}

//     setPendingPayment(true)

//     try {
//       const res = await subscribe({
//         variables: {
//           product: PRODUCT_ID,
//           variant: variant.id,
//           returnUrl: `${window.location.origin}/registrer/#waiting`,
//           coupon: validCoupon ? couponData?.code : undefined,
//         },
//       })

//       const redirectUrl = get(res, 'data.subscribeToPlan.redirectUrl')

//       if (redirectUrl) {
//         window.location.href = redirectUrl
//         postData();
//       }
//     } catch (e) {
//       if (e.message.match(/active plan exist/)) {
//         setPendingPayment(false)
//         setStep('existing')
//       }
//     }
//   }

//   // Persist token to session storage
//   useEffect(() => {
//     const token = signupToken || socialSignupToken || loginToken

//     async function setToken() {
//       if (!window.sessionStorage.getItem('token') && token) {
//         window.sessionStorage.setItem('token', token)
//         await client.resetStore()

//         // refetch user data after setting token
//         await refetch()
//       }

//       // go to next step
//       if (step === 'user' && token) {
//         moveToStep('payment')
//       }
//     }

//     setToken()
//   }, [moveToStep, refetch, signupToken, socialSignupToken, step])

//   const handleAppleIDLogin = data => {
//     setError(null)

//     socialSignup(get(data, 'detail.authorization.id_token'), 'apple')
//   }

//   const handleFacebookLogin = response => {
//     setError(null)

//     if (response.status === 'unknown') {
//       setError('Innloggingen ble avbrutt')
//       return
//     }

//     socialSignup(get(response, 'authResponse.accessToken'), 'facebook')
//   }

//   function handleAppleIDError(error) {
//     if (error.detail.error === 'popup_closed_by_user') {
//     }
//     setError('Innloggingen med AppleID feilet')
//   }

//   const getConsentHandler = (type, consent) => () => {
//     setConsentAnswered(true)

//     if (!consent) {
//       return
//     }

//     giveConsent({ variables: { type } })
//   }

//   const USER_STEP = {
//     title: action === 'signup' ? 'Registrer deg' : 'Logg inn',
//     disclaimer: (
//       <>
//         Ved å fortsette godkjenner du <Link to="/vilkar">vilkårene</Link> for
//         kjøp og bruk av Foodsteps og bekrefter at du har lest{' '}
//         <Link to="/personvern">personvernerklæringen</Link>.
//       </>
//     ),
//     items: [
//       {
//         id: 'social-login',
//         item: 'custom',
//         children: (
//           <>
//             <div
//               style={{
//                 // Hack.... Make a button group component?
//                 display: 'flex',
//                 alignItems: 'center',
//                 flexDirection: 'column',
//               }}
//             >
//               <SignInWithApple
//                 onSuccess={handleAppleIDLogin}
//                 onError={handleAppleIDError}
//                 onClick={() => setError(null)}
//               />

//               <SignInWithFacebook
//                 onClick={() => {
//                   setError(null)
//                   setAuthType('social')
//                   FB.login(handleFacebookLogin)
//                 }}
//               />

//               {authType === 'social' && error ? <p>{error}</p> : null}
//             </div>
//           </>
//         ),
//       },
//       {
//         item: 'input',
//         label: 'E-post',
//         type: 'email',
//         value: formData.email || '',
//         onChange: updateForm('email'),
//         id: 'email',
//       },
//       {
//         item: 'input',
//         label: 'Lag passord',
//         type: 'password',
//         value: formData.password || '',
//         onChange: updateForm('password'),
//         name: 'new-password',
//         id: 'new-password',
//         autoComplete: 'new-password',
//       },
//       // {
//       //  item: 'checkbox',
//       //  label:
//       //    'Jeg ønsker nyhetsbrev fra Foodsteps med ukemenyer, tips og tilbud.',
//       //  value: formData.newsletter || '',
//       //  onChange: updateForm('newsletter'),
//       //  name: 'newsletter',
//       //  id: 'newsletter',
//       // },
//       {
//         id: 'do-login',
//         item: 'custom',
//         children: (
//           <div style={{ textAlign: 'center' }}>
//             Har du {action === 'login' ? 'ikke ' : ''}en konto?{' '}
//             {action === 'signup' ? (
//               <Button link onClick={() => setAction('login')}>
//                 Logg inn i stedet
//               </Button>
//             ) : null}
//             {action === 'login' ? (
//               <Button link onClick={() => setAction('signup')}>
//                 Registrer deg i stedet
//               </Button>
//             ) : null}
//           </div>
//         ),
//       },
//       {
//         id: 'login-error',
//         item: 'warning',
//         value: (authType === 'credentials' && error) || null,
//       },
//     ],
//     onSubmit: action === 'signup' ? signup : login,
//     submit: 'Fortsett',
//   }

//   const PAYMENT_STEP = {
//     title: 'Betaling',
//     disclaimer: (
//       <>
//         Ved å fortsette godkjenner du <Link to="/vilkar">vilkårene</Link> for
//         kjøp og bruk av Foodsteps og bekrefter at du har lest{' '}
//         <Link to="/personvern">personvernerklæringen</Link>.
//       </>
//     ),
//     items: [
//       {
//         id: 'subscription',
//         item: 'custom',
//         children: (
//           <Price
//             id={PRODUCT_ID}
//             products={products}
//             hideAction
//             campaign={validCoupon ? couponData : undefined}
//           />
//         ),
//       },
//       {
//         id: 'continue',
//         item: 'custom',
//         children: (
//           <div style={{ textAlign: 'center' }}>
//             {!coupon && coupon !== '' ? (
//               <Button dark link onClick={() => setCoupon('')}>
//                 Jeg har en rabattkode
//               </Button>
//             ) : (
//               <>
//                 {coupon &&
//                 verifyCouponCalled &&
//                 !loadingCoupon &&
//                 !couponData ? (
//                   <Article.Text>Den rabattkoden finnes ikke</Article.Text>
//                 ) : null}
//                 {coupon &&
//                 verifyCouponCalled &&
//                 !loadingCoupon &&
//                 couponData &&
//                 !validCoupon ? (
//                   <Article.Text>
//                     Rabattkoden er allerede brukt. Ta kontakt med
//                     post@foodsteps.no om du mener det ikke stemmer.
//                   </Article.Text>
//                 ) : null}
//                 <Input
//                   label="Rabattkode"
//                   onChange={setCoupon}
//                   value={coupon}
//                   autoFocus
//                   dark
//                 />
//               </>
//             )}

//             <br />

//             <SharedButton
//               type="submit"
//               primary
//               vipps
//               className="form__button"
//               onClick={handlePayment}
//             >
//               Betal med vipps
//             </SharedButton>
//           </div>
//         ),
//       },
//     ],
//     submit: null,
//   }

//   const TIMEOUT_STEP = {
//     title: 'Det kom ikke noen bekreftelse',
//     close: true,
//     items: [
//       {
//         item: 'text',
//         value:
//           'Hvis du fullførte betalingen og ser i vipps-appen din at du har betalt løser det seg mest sannsynlig av seg selv hvis du venter noen minutter.',
//       },
//       {
//         item: 'text',
//         value:
//           'Om det ikke løser seg må du ta kontakt med oss, så skal vi se på saken. Du kan laste ned appen mens du venter.',
//       },
//       {
//         item: 'button',
//         label: 'Last ned fra AppStore',
//         to: DOWNLOAD_FROM_APPLE,
//         id: 'appstore',
//         primary: true,
//         icon: 'download',
//         full: true,
//       },
//       {
//         item: 'button',
//         label: 'Last ned fra Google Play',
//         to: DOWNLOAD_FROM_GOOGLE,
//         id: 'googleplay',
//         primary: true,
//         icon: 'download',
//         full: true,
//       },
//     ],
//   }

//   const COMPLETED_STEP = {
//     title: 'Takk, nå er du snart i gang.',
//     close: true,
//     items: [
//       ...(!consentAnswered
//         ? [
//             {
//               item: 'custom',
//               id: 'marketing',
//               children: (
//                 <div>
//                   <Article.Text>
//                     Ønsker du å motta nyheter og tilbud fra Foodsteps via epost?
//                   </Article.Text>
//                   <Button
//                     primary
//                     onClick={getConsentHandler('marketing', true)}
//                   >
//                     Ja takk!
//                   </Button>{' '}
//                   <Button
//                     primary
//                     onClick={getConsentHandler('marketing', false)}
//                   >
//                     Nei takk
//                   </Button>
//                 </div>
//               ),
//             },
//           ]
//         : [
//             {
//               item: 'text',
//               value:
//                 'Last ned appen på mobilen din, logg deg på og kos deg på kjøkkenet! ',
//             },
//             {
//               item: 'button',
//               label: 'Last ned fra AppStore',
//               to: DOWNLOAD_FROM_APPLE,
//               id: 'appstore',
//               primary: true,
//               icon: 'download',
//               full: true,
//             },
//             {
//               item: 'button',
//               label: 'Last ned fra Google Play',
//               to: DOWNLOAD_FROM_GOOGLE,
//               id: 'googleplay',
//               primary: true,
//               icon: 'download',
//               full: true,
//             },
//           ]),
//     ],
//   }

//   const EXISTING_STEP = {
//     title: 'Du har alt et abonnement',
//     close: true,
//     items: [
//       {
//         item: 'text',
//         value:
//           'Siden du har et abonnement allerede trenger du ikke å kjøpe. Last ned appen i stedet!',
//       },
//       {
//         item: 'button',
//         label: 'Last ned fra AppStore',
//         to: DOWNLOAD_FROM_APPLE,
//         id: 'appstore',
//         primary: true,
//         icon: 'download',
//         full: true,
//       },
//       {
//         item: 'button',
//         label: 'Last ned fra Google Play',
//         to: DOWNLOAD_FROM_GOOGLE,
//         id: 'googleplay',
//         primary: true,
//         icon: 'download',
//         full: true,
//       },
//     ],
//   }
//   const postData = async () => {
//     console.log("Inside postdate");
//     try {
//       const currentDate = new Date();
//       var start = currentDate.toString();
//       currentDate.setMonth(currentDate.getMonth() + 1)
//       const data = {
//         email_id: formData.email,
//         is_vipps_subscription: 1,
//         is_gift_card_applied: 0,
//         month: 1,
//         vipps_start_datetime: '',
//         vipps_end_datetime: '',
//         giftcard_start_datetime: start,
//         giftcard_end_datetime: currentDate.toString(),
//       };
// console.log("post data:"+ data);
//       // Make a POST request with Axios
//       const response = await axios.post('http://119.18.52.238:3004/api/user/addvgcsubscriptions', data);

//       // Handle the response
//       // setResponse(response.data);
//       console.log(response);
//     } catch (error) {
//       // Handle errors
//       console.error('Error:', error);
//     }
//   };
//   return (
//     <>
//       <SEO title="Registrering" />
//       {pendingPayment && (
//         <Loader text="Snakker med Vipps" delay={0} type="filled" />
//       )}
//       {step === 'waiting' && (
//         <Loader
//           text="Venter på bekreftelse fra vipps"
//           delay={0}
//           type="filled"
//         />
//       )}

//       <Form
//         step={step}
//         setStep={moveToStep}
//         handleBack={() => navigate('/')}
//         steps={{
//           user: USER_STEP, // signup
//           payment: PAYMENT_STEP, // payment, ending in vipps
//           waiting: { title: '' }, // after returning from vipps, pending verification
//           timeout: TIMEOUT_STEP, // payment verification timed out
//           completed: COMPLETED_STEP, // completed purchase
//           existing: EXISTING_STEP, // users with plans go here
//         }}
//         navigation
//       />
//     </>
//   )
// }

// Page.propTypes = {
//   location: T.object.isRequired,
//   data: T.shape({
//     products: T.shape({
//       initialProducts: T.arrayOf(T.object).isRequired,
//     }),
//   }).isRequired,
// }

export const query = graphql`
  query {
    products: foodsteps {
      initialProducts: products {
        id
        title
        description
        includes
        variants {
          promotion {
            price
            title
            id
            description
            eligible
          }
          price
          months
        }
      }
    }
  }
`
