import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'

import LOGIN_MUTATION from '../queries/login'

export default function useLogin(errorState, setAuthType, email, password) {
  const [, setError] = errorState
  const [login] = useMutation(LOGIN_MUTATION)

  const [token, setToken] = useState(
    typeof window !== 'undefined'
      ? window.sessionStorage.getItem('token')
      : null,
  )

  async function doLogin() {
    setAuthType('credentials')
    setError(null)

    if (!email || !password) {
      setError('Fyll inn e-post og passord')
      return
    }

    const {
      data: { login: { token = null, error: loginError = null } = {} } = {},
    } = await login({ variables: { email, password } })

    if (loginError) {
      if (loginError.code === 1) {
        setError('Feil brukernavn eller passord')
        return
      }

      setError('En feil oppsto under innlogging')
      return
    }

    setToken(token)
  }

  return { token, login: doLogin }
}
