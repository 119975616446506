import { gql } from 'apollo-boost'

export default gql`
  mutation SignupSocial(
    $accessToken: String!
    $type: SocialPlatform!
    $userData: UserData
  ) {
    signupSocial(
      accessToken: $accessToken
      type: $type
      userData: $userData
    ) {
      token
      user {
        id
        plans {
          id
          product {
            id
          }
        }
      }
      error {
        code
        message
      }
    }
  }
`
