import './index.scss'

import React, { useEffect } from 'react'
import T from 'prop-types'

export default function SignInWithApple({ onSuccess, onError, onClick }) {
  useEffect(() => {
    let timeout

    function renderButton(retryCount = 0) {
      if (!window.AppleID && retryCount < 10) {
        timeout = setTimeout(
          () => renderButton(retryCount + 1),
          (retryCount + 1) * 100,
        )
        return
      }

      window.AppleID && window.AppleID.auth.renderButton()
    }

    renderButton()

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    const handleAppleLoginSuccess = data => onSuccess(data)
    const handleAppleLoginError = error => onError(error)

    document.addEventListener('AppleIDSignInOnSuccess', handleAppleLoginSuccess)
    document.addEventListener('AppleIDSignInOnFailure', handleAppleLoginError)

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', handleAppleLoginSuccess)
      document.removeEventListener('AppleIDSignInOnSuccess', handleAppleLoginSuccess)
    }
  }, [onError, onSuccess])

  return (
    <div
      id="appleid-signin"
      className="sign-in-with-apple"
      data-color="black"
      data-border="true"
      data-type="continue"
      onClick={onClick}
    />
  )
}

SignInWithApple.propTypes = {
  onClick: T.func,
  onSuccess: T.func.isRequired,
  onError: T.func.isRequired,
}

SignInWithApple.defaultProps = {
  onClick: () => {},
}
