import './index.scss'

import React from 'react'

import Button from '../Button'

export default function SignInWithFacebook({ ...props }) {
  return (
    <Button dark small {...props} className="sign-in-with-facebook">
      Fortsett med Facebook
    </Button>
  )
}
