import { gql } from 'apollo-boost'

export default gql`
  mutation Signup($email: String! $password: String!) {
    signup(email: $email password: $password) {
      token
      user {
        id
        plans {
          id
          product {
            id
          }
        }
      }
      error {
        code
        message
      }
    }
  }
`
