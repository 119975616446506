import { gql } from 'apollo-boost'

export default gql`
  mutation RefreshPendingPlans {
    refreshPendingPlans {
      updatedPlans {
        id
        start
        end
        product {
          id
        }
      }
    }
  }
`
