import { gql } from 'apollo-boost'

export default gql`
  query VerifyCoupon($code: String!) {
    verifyCoupon(code: $code) {
      valid
      coupon {
        id
        code
        months
        price
        planPrice
      }
    }
  }
`
